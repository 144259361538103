import clsx from 'clsx';
import Link from 'next/link';
import type { FC, PropsWithChildren } from 'react';

interface ButtonBaseProps extends PropsWithChildren {
	className?: string;
	disabled?: boolean;
	filled?: boolean;
	id?: string;
	label?: string;
	variation?:
		| 'icon-large-no-fill'
		| 'icon-large'
		| 'icon-small-no-fill'
		| 'icon-small'
		| 'link'
		| 'primary-square-no-fill'
		| 'primary-square'
		| 'primary'
		| 'secondary'
		| 'share'
		| 'social'
		| 'tertiary';
}

export interface LinkButtonProps extends ButtonBaseProps {
	href: string;
	onClick?: never;
	rel?: string;
	target?: '_blank' | '_parent' | '_self' | '_top';
	type?: never;
}

export interface ClickButtonProps extends ButtonBaseProps {
	href?: never;
	onClick: () => void;
	type?: 'button' | 'submit';
}

export type ButtonProps = ClickButtonProps | LinkButtonProps;

export const Button: FC<ButtonProps> = ({
	children,
	className,
	disabled,
	href,
	id,
	label,
	onClick,
	type = 'button',
	variation = 'primary',
	...props
}) => {
	const cx = clsx('button', className, {
		[`button--${variation}`]: variation,
		'button--disabled': disabled,
	});

	const { rel, target } = props as LinkButtonProps;

	return onClick ? (
		<button
			aria-disabled={disabled}
			aria-label={label}
			className={cx}
			disabled={disabled}
			id={id}
			onClick={onClick}
			type={type === 'submit' ? 'submit' : 'button'}
		>
			{children}
		</button>
	) : (
		<Link
			aria-disabled={disabled}
			aria-label={label}
			className={cx}
			href={href}
			id={id}
			rel={rel}
			target={target}
		>
			{children}
		</Link>
	);
};
