'use client';

import clsx from 'clsx';
import type { InputCommonProps } from '@/types/inputs';
import type { FC, ChangeEvent, ElementType } from 'react';
import { Label, type LabelProps } from '@/components/raven/Inputs/Label';
import {
	HintText,
	type HintTextProps,
} from '@/components/raven/Inputs/HintText';

import styles from './styles.module.css';

export type TextFieldProps = {
	as?: ElementType;
	autoComplete?: 'off' | 'on';
	onBlur?: (event: ChangeEvent<HTMLInputElement>) => void;
	onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
	type?: 'date' | 'email' | 'password' | 'search' | 'tel' | 'text';
	variant?: 'filled' | 'outlined';
} & InputCommonProps &
	Pick<HintTextProps, 'hint'> &
	Pick<LabelProps, 'labelHidden'>;

export const TextField: FC<TextFieldProps> = ({
	as: Component = 'input',
	autoComplete = 'off',
	className,
	disabled = false,
	errorMessage,
	hint = '',
	label,
	labelHidden,
	name,
	required = false,
	type = 'text',
	variant = 'outlined',
	...props
}) => {
	return (
		<div className={clsx(styles.inputContainer, className)}>
			<Label htmlFor={name} labelHidden={labelHidden}>
				{label}
			</Label>
			<Component
				aria-invalid={!!errorMessage}
				autoComplete={autoComplete}
				className={clsx(styles.inputField, {
					[styles.outlined]: variant === 'outlined',
					[styles.textFieldFilled]: variant === 'filled',
					[styles.textFieldHasError]: errorMessage,
				})}
				disabled={disabled}
				id={name}
				name={name}
				required={required}
				type={type}
				{...props}
			/>
			<HintText errorMessage={errorMessage} hint={hint} />
		</div>
	);
};
