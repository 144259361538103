import clsx from 'clsx';
import type { FC } from 'react';

import styles from './styles.module.css';

export interface HintTextProps {
	className?: string;
	errorMessage?: string;
	hint?: string;
}

export const HintText: FC<HintTextProps> = ({
	className,
	errorMessage,
	hint,
}) => {
	if (!hint) {
		return null;
	}

	return (
		<span
			className={clsx(
				styles.hintText,
				{
					[styles.hintHasError]: errorMessage,
				},
				className,
			)}
		>
			{hint}
		</span>
	);
};
