import {
	initializeApp,
	type FirebaseApp,
	type FirebaseOptions,
} from 'firebase/app';

let app: FirebaseApp;
export const firebaseApp = (firebaseConfig: FirebaseOptions) => {
	if (app === undefined) {
		app = initializeApp(firebaseConfig);
	}

	return app;
};
