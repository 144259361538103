import clsx from 'clsx';
import type { FC, PropsWithChildren } from 'react';

import styles from './styles.module.css';

interface CommonLabelProps extends PropsWithChildren {
	as?: 'label' | 'legend';
	className?: string;
	labelHidden?: boolean;
}

interface LabelSpecificProps extends CommonLabelProps {
	as?: 'label';
	htmlFor: string;
}

interface LegendSpecificProps extends CommonLabelProps {
	as: 'legend';
	htmlFor?: never;
}

export type LabelProps = LabelSpecificProps | LegendSpecificProps;

export const Label: FC<LabelProps> = ({
	as: Component = 'label',
	children,
	className,
	htmlFor,
	labelHidden,
}) => (
	<div
		className={clsx(className, {
			[styles.inputLabelHidden]: labelHidden,
		})}
	>
		<Component
			className={styles.inputLabel}
			{...(Component === 'label' && { htmlFor })}
		>
			{children}
		</Component>
	</div>
);
